import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import "./AddCustomModule.css";
// import RootFolderSelection from "../RootFolderSelection/RootFolderSelection";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import axios from "axios";
import * as ApiCall from "./../../../../../API/ApiCalling";
import logo from "../../../../../../Images/easypluginz.png";
import connectionError from "../../../../../../Images/connection-error.png";

const ZOHO = window.ZOHO;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 550,
  borderRadius: "20px",
  // bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  py: 4,
};

function AddCustomModule({
  module,
  connname,
  orgid,
  apikey,
  datacenterurl,
  setNewSettingCreated,
  setNewSetting,
}) {
  const snap = useSnapshot(widState);
  const [activeClass, setActiveClass] = useState("Leads");
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setTriggerFetch(!triggerFetch);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [defaultModule, setDefaultModule] = useState([
    "Leads",
    "Accounts",
    "Contacts",
    "Deals",
  ]);

  const [triggerFetch, setTriggerFetch] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [selectedModuleValue, setSelectedModuleValue] = useState(null);
  const [selectedFieldValue, setSelectedFieldValue] = useState(null);
  const [firstCreateBtnHit, setFirstCreateBtnHit] = useState(false);

  useEffect(() => {
    async function fetchFields(params) {
      const fieldDetails = await ZOHO.CRM.META.getFields({ Entity: module });
      // ;
      if (fieldDetails?.error) {
        return "";
      }
      const fieldlist = fieldDetails?.fields
        ?.filter(
          (item) =>
            item.field_label &&
            item.api_name &&
            item.data_type === "text" &&
            item?.json_type === "string"
        )
        .map((item) => {
          return {
            displayName: item.field_label,
            apiName: item.api_name,
          };
        });
      // ;
      setFieldList((prev) => fieldlist);
    }
    if (module) {
      fetchFields();
    }
  }, [module]);

  const handleFieldFetch = async (apiName) => {
    ZOHO.CRM.META.getFields({ Entity: apiName }).then(function (data) {
      // // ;
      const resp = data?.fields;
      let finalResp = resp?.map((item) => {
        if (
          item.field_label &&
          item.api_name &&
          item.data_type === "text" &&
          item?.json_type === "string"
        ) {
          return {
            displayName: item.field_label,
            apiName: item.api_name,
          };
        }
      });
      const filteredResp = finalResp?.filter((item) => Boolean(item));
      setFieldList((prev) => filteredResp);
    });
  };

  const handleCreateCustomModule = async () => {
    const settingData = {
      settingSchema: {
        moduleApiName: module,
        fieldApiName: {
          display_name: selectedFieldValue?.displayName,
          api_name: selectedFieldValue?.apiName,
        },
        status: true,
      },
      settingType: "relatedList",
      moduleApiName: module,
    };

    const createSetting = await ApiCall.upsertData(
      settingData,
      connname,
      orgid,
      apikey,
      datacenterurl
    );

    if (createSetting?.error) {
      return "";
    }

    setNewSettingCreated((prev) => true);
    setNewSetting((prev) => false);
    setOpen((prev) => false);
    snap.setLoading(true);
    // ;

    /*
    setFirstCreateBtnHit((prev) => true);
    if (selectedModuleValue && selectedFieldValue) {
      let modules = defaultModule;
      modules.push(selectedModuleValue.displayName);
      setDefaultModule([...new Set(modules)]);
      handleClose();
    }
    /*
    */
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 8,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={connectionError} alt="" height={100} width={130} />
              </Box>
              <Typography align="center" variant="h2">
                No Settings Found!
              </Typography>
              <Typography sx={{ fontSize: 16, lineHeight: 1.7 }} align="center">
                Please select Field Name, where Root Folder ID is saved.
                <br /> This widget will show all Folders/Subfolders based on the
                Selected Fields Flder ID
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // gap: 3,
            }}
          >
            {/* <Typography sx={{ fontSize: "16px" }}></Typography> */}
            <Autocomplete
              loading={fieldList.length ? false : true}
              disablePortal
              id="combo-box-demo"
              PaperProps={{
                style: { color: "red" },
              }}
              options={fieldList}
              getOptionLabel={(option) => option.displayName}
              value={selectedFieldValue}
              onChange={(e, v) => {
                if (v) {
                  setSelectedFieldValue((prev) => v);
                }
              }}
              renderInput={(params) => (
                <TextField
                  error={
                    firstCreateBtnHit && !selectedFieldValue ? true : false
                  }
                  label="Field name"
                  {...params}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 18, height: 30 },
                  }}
                  sx={{ width: 330 }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 1.5 }}>
            {/* <Button
            onClick={handleClose}
            sx={{
              textTransform: "none",
              width: "6rem",
              borderRadius: "4px",
              height: "2.3rem",
              color: "#1850A0",
              border: "none",
              // bgcolor: "transparent",
              mr: 2,
              "&:hover": { border: "none", bgcolor: "#cfd7e6" },
            }}
            variant="outlined"
          >
            Cancel
          </Button> */}
            <Button
              onClick={handleCreateCustomModule}
              sx={{
                borderRadius: "4px",
                textTransform: "none",
                height: "2.3rem",
                bgcolor: "#1F6DDE",
              }}
              variant="contained"
            >
              Save Related List Setting
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0.1,
          right: 8,
          // display: "flex",
          // justifyContent: "flex-end",
          // mr: 2,
          // mt: "auto",
        }}
      >
        {/* <img src={logo} alt="logo" height={36} width={160} /> */}
      </Box>
    </Box>
  );
}

export default AddCustomModule;
